// Spinner.js
import React from 'react';
import './Spinner.css'; // Ensure you have this CSS file in your project

const Spinner = () => {
    return (
        <div className="spinner-container">
            <img src="logo.png" alt="GoatCraft Surf Co. Logo" className="spinner-logo" />
            <div className="loading-text">Loading<span className="dots">...</span></div>
        </div>
    );
};

export default Spinner;