// App.js
import React, { useState, useEffect } from 'react';
import Spinner from './Spinner';
import Announcement from './Announcement';
import './App.css';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 5000); // 5-second loading
  }, []);

  return (
      <div className="App">
        {loading ? <Spinner /> : <Announcement />}
      </div>
  );
}

export default App;