import React from 'react';
import './Announcement.css'; // You'll need to create this CSS file

const Announcement = () => {
    return (
        <div className={"container"}>
                <div className="announcement-container">
                    <h2 style={{marginBottom: "0px", paddingBottom: 0}}>Website Coming Soon</h2>
                    <p style={{marginTop: "0px", paddingBottom: 0}}>Expertly handcrafted jewelry</p>
                    <img src="logo.png" className="main-logo" alt="GoatCraft Surf Co. Logo"/>
                    <p className={"desc"}>Dive into the world of exquisite, handcrafted surf-inspired jewelry, where every wave, every drop of the ocean, comes to life in our stunning pieces. We're not just about jewelry; it's a tribute to the surf culture, a celebration of the bond between the sea and the shore. From intricate pendants that capture the spirit of the waves to bespoke pieces that resonate with the rhythm of the ocean, our expert team of artisans pours love, care, and unmatched craftsmanship into every item. Each creation is a testament to our dedication to quality and a reflection of our passion for the surf lifestyle.</p>
                    <h1 style={{marginBottom:"1px", paddingBottom:"1px"}}>GoatCraft Surf Co.</h1>
                    <h3 style={{marginTop:0, paddingTop:0}}><a href="mailto:hello@goatcraftsurf.co">hello@goatcraftsurf.co.uk</a></h3>
                </div>
        </div>

    );
};

export default Announcement;